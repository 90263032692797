 
import { Button } from "antd";
import { Link } from "react-router-dom";
import Float from "../components/Float";
import Footer from "../template/Footer";
import Nav from "../template/Nav";
import Buy from "./Buy";

const styles = {
    accordion: {
        'a, a:visited, a:hover, a:active': {
            color: '#1E1941',
            border: "0px"
          },
        textAlign: 'left', 
        backgroundColor: "#1E1941", 
        border: "0px",
    }
}
const Home = () => {


    
  return (
    <>
    
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        paddingTop: 15,
        paddingBottom: 5
      }}
    >
      <p>
        <Link
          className="btn my-2 my-sm-0"
          style={{ backgroundColor: "#00f6ff", color: "black", borderRadius: 40 }}
          to="#"
          role="button"
          target="_blank"
        > LEARN MORE</Link>
         
        
        Over 100K in CTSI up for grabs... if you can hack Cartesi Rollups.
      </p>
    </div>
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#00f6ff",
        backgroundImage: 'url("/images/layer-1.png")',
        height: 600,
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className="row">
        <div className="col-12">
          <nav
            className="navbar navbar-expand-lg navbar-expand-md navbar-light "
            style={{ backgroundColor: "#cbfbfb", borderRadius: 10, margin: 20 }}
          >
            <a className="navbar-brand" href="/">
              <img
                src="/images/logocartesi.png"
                width={60}
                height={60}
                alt=""
                loading="lazy"
              />
              Cartesi
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarTogglerDemo02"
              style={{ alignItems: "left" }}
            >
              <ul className="navbar-nav" style={{ alignItems: "right" }}>
                <li className="nav-item  ">
                  <a className="nav-link" href="#">
                    About{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Developers
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Governance &amp; Grant
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Community
                  </a>
                </li>
              </ul>
              <form className="form-inline" style={{ alignContent: "right" }}>
                <Link
                  className="btn my-2 my-sm-0"
                  style={{ backgroundColor: "black", color: "white" }}
                  to="/buy-ctis"
                >
                  Buy CTI Token
                </Link>
              </form>
            </div>
          </nav>
        </div>
        <div className="col-12">
          <div
            style={{
              textAlign: "center",
              marginBottom: 20,
              backgroundColor: "none"
            }}
          >
            <h1 className="display-4">
              <b>
                Application-specific rollups with <br />a Linux runtime.
              </b>
            </h1>
            <p>Scaling Computation. Transcending EVM Limitations.</p>
            <a
              className="btn my-2 my-sm-0"
              style={{ backgroundColor: "black", color: "white" }}
              href="#swap"
              role="button"
            >
              START SWAP
            </a>
            <Link
              className="btn my-2 my-sm-0"
              style={{ backgroundColor: "black", color: "white" }}
              role="button"
              to="/buy-ctis"
            >
             CONNECT WEB 3
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid" style={{ marginTop: 40 }}>
      <div className="row">
        <div className="col-md-6 col-lg-6">
          <h3 style={{ margin: 30, fontSize: "2.5em", padding: 10 }}>
            Moving beyond shared <br />
            computation and EVM <br />
            design limitations.
            <br /> <br />
            <a
              className="btn my-2 my-sm-0"
              style={{ backgroundColor: "black", color: "white" }}
              role="button"
            >
              BUY CTIS TOKEN
            </a>
          </h3>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="col-xs-12">
            <div className="media" style={{ padding: 10 }}>
              <img
                src="https://cdn.sanity.io/files/zg5gx8g4/production/3ebdf5178c50628ec97d4fc2bb0ab0f2ae09d503.svg"
                className="mr-3"
                alt="..."
              />
              <div className="media-body">
                <h5 className="mt-0" style={{ color: "black" }}>
                  <b>A full CPU for each dApp</b>
                </h5>
                <span style={{ color: "#d0d0d0" }}>
                  With Cartesi Rollups, dApps don't compete with each other for
                  processing power like they do on Ethereum and existing L2
                  scaling solutions.
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="media" style={{ padding: 10 }}>
              <img
                src="https://cdn.sanity.io/files/zg5gx8g4/production/f23467a3850bbfed90d96aaf3922783f340665e8.svg"
                className="mr-3"
                alt="..."
              />
              <div className="media-body">
                <h5 className="mt-0" style={{ color: "black" }}>
                  <b>A full suite of open-source libraries for your dApp</b>
                </h5>
                <span style={{ color: "#d0d0d0" }}>
                  With the Cartesi Virtual Machine, dApp developers move beyond
                  the EVM and gain access to the full suite of code libraries,
                  programming languages, and open-source tooling they're
                  accustomed to.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div
        className="row"
        style={{
          borderRadius: 20,
          border: "solid 2px  #d0d0d0",
          margin: 10,
          padding: 10
        }}
      >
        <div
          className="col-lg-12 col-md-12"
          style={{ textAlign: "center", marginTop: 100 }}
        >
          <span
            className="btn my-2 my-sm-0"
            style={{
              backgroundColor: "#00f6ff",
              color: "black",
              borderRadius: 20
            }}
            role="button"
          >
            TECHNOLOGY
          </span>
          <h3 style={{ margin: 10, fontSize: "2em", padding: 10 }}>
            A boundless design space for <br /> cutting-edge developers.
          </h3>
        </div>
        <div
          className="col-lg-12 col-md-12 col-xs-12"
          style={{ borderRadius: 10, backgroundColor: "black" }}
        >
          <div className="row" style={{ color: "white" }}>
            <div className="col-xs-12 col-lg-6 col-md-6">
              <h2 style={{ fontSize: "3em", padding: 10, textAlign: "center" }}>
                App-specific <br />
                rollups
              </h2>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <p style={{ padding: 10 }}>
                Cartesi gives your dApp its own optimistic rollup with a dedicated
                CPU, bringing significant gains in computational scalability
                without compromising on decentralization, security and censorship
                resistance.
              </p>
            </div>
          </div>
          <div className="row">
             
            <div
                className="col-md-5 col-lg-5 col-xs-12"
                style={{
                  borderRadius: 20,
                  padding: 10,
                  margin: 10,
                  backgroundColor: "rgb(56, 55, 55)"
                }}
              >
                <img src="https://cdn.sanity.io/images/zg5gx8g4/production/b898352f3a127289b1f2a4512949dd6e4f0e6419-79x46.svg" />
                <h3 style={{ color: "white" }}>Predictable and stable</h3>
                <p style={{ color: "darkgray" }}>
                  dApps powered by Cartesi Rollups don’t compete for network
                  resources with other dApps, offering predictable and stable
                  fees.
                </p>
               
            </div>
            <div
              className="col-md-6 col-lg-6 col-xs-12"
              style={{
                margin: 10,
                borderRadius: 20,
                padding: 10,
                backgroundColor: "rgb(56, 55, 55)"
              }}
            >
              <img src="https://cdn.sanity.io/images/zg5gx8g4/production/1f9b3b7947639620e52879a5318c752cc25acdec-75x40.svg" />
              <h3 style={{ color: "white" }}>Flexible and customizable</h3>
              <p style={{ color: "darkgray" }}>
                Developers have more control and flexibility over the application
                stack, allowing for customizable gas metering, data availability
                solutions, and other dApp-specific needs.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-12 col-md-12 col-xs-12"
          style={{ borderRadius: 10, backgroundColor: "black", marginTop: 20 }}
        >
          <div className="row" style={{ color: "white" }}>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <h2 style={{   padding: 10 }}>
                Cartesi Virtual <br /> Machine
              </h2>
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6">
              <p style={{ padding: 10 }}>
                Cartesi Rollups support a VM with a RISC-V architecture, making it
                possible to boot Linux or other mature operating systems.
                Developers can bring their ideas to life through rich code
                libraries, programming languages, and open-source tooling.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-12 col-md-12">
              <video width="100%" autoPlay="">
                <source src="/images/cartesi_video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="row" style={{margin: 10}}>
             
            <div
                className="col-md-6 col-lg-6 col-xs-12"
                style={{
                  borderRadius: 20,
                  padding: 10,
                  
                  backgroundColor: "rgb(56, 55, 55)"
                }}
              >
                <img src="https://cdn.sanity.io/files/zg5gx8g4/production/7f881ba2f990cd96a639e858a046ff87df26b94d.svg" />
                <h3 style={{ color: "white" }}>Space for exploration</h3>
                <p style={{ color: "darkgray" }}>
                  Explore a rich new design space that supports decades of
                  existing code libraries, programming languages, and open-source
                  tooling.
                </p>
            </div>
           
            <div
              className="col-md-6 col-lg-6 col-xs-12"
              style={{
                 
                borderRadius: 20,
                padding: 10,
                
                backgroundColor: "rgb(56, 55, 55)"
              }}
            >
              <img src="https://cdn.sanity.io/files/zg5gx8g4/production/7eef7679a1924b578c0b4fd1877d9f1ac8e0d9aa.svg" />
              <h3 style={{ color: "white" }}>Supreme processing power</h3>
              <p style={{ color: "darkgray" }}>
                Build more intricate and computationally intensive dApps without
                having to reinvent new abstractions every time.
              </p>
            </div>
          </div>
          <div className="row" style={{margin: 10}}>
            <div
              className="col-md-4 col-lg-4 col-xs-12"
              style={{
                borderRadius: 20,
                padding: 4,
                
                backgroundColor: "rgb(56, 55, 55)"
              }}
            >
              <img src="https://cdn.sanity.io/files/zg5gx8g4/production/4a67f57f202911f14e4b959f5735ca48b3beed34.svg" />
              <p style={{ color: "white" }}>Fully-fledged OS</p>
              <p style={{ color: "darkgray" }}>
                Boot a mature operating system inside the Cartesi Virtual Machine.
              </p>
            </div>
            <div
              className="col-md-4 col-lg-4 col-xs-12"
              style={{
                
                borderRadius: 10,
                padding: 4,
                backgroundColor: "rgb(56, 55, 55)"
              }}
            >
              <img src="https://cdn.sanity.io/files/zg5gx8g4/production/c54cf3ee651c7ae5dc5e3f4629ad77852514da13.svg" />
              <p style={{ color: "white" }}>Focus</p>
              <p style={{ color: "darkgray" }}>
                Optimize specific dApp logic to bring your ideas to life, free
                from the intricacies of the blockchain itself and the design
                tradeoffs of the EVM.
              </p>
            </div>
            <div
              className="col-md-4 col-lg-4 col-xs-12"
              style={{
              
                borderRadius: 10,
                padding: 4,
                backgroundColor: "rgb(56, 55, 55)"
              }}
            >
              <img src="https://cdn.sanity.io/files/zg5gx8g4/production/c2a5b1df81bfb9456cdaf0452e0e640a98481734.svg" />
              <p style={{ color: "white" }}>Base layer guarantees</p>
              <p style={{ color: "darkgray" }}>
                Inherit security and censorship resistance from Ethereum.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid" style={{ padding: 20 }}>
      <div className="row">
        <div className="col-md-6 col-lg-6">
          <h3 style={{ fontSize: "2.5em", padding: 10 }}>
            Community repository of experimental dApps developed with Cartesi
            Rollups.
            <br /> <br />
            <Link
              className="btn my-2 my-sm-0"
              style={{ backgroundColor: "black", color: "white" }}
              role="button"
              to={'/buy-ctis'}
            >
              Connect Web3
            </Link>
          </h3>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="col-12">
            <div className="media" style={{ padding: 10 }}>
              <img
                src="https://cdn.sanity.io/files/zg5gx8g4/production/d31e7c18c2e8e75b09baaaecdb89e6a62ddbb7fe.svg"
                className="mr-3"
                alt="..."
              />
              <div className="media-body">
                <h5 className="mt-0" style={{ color: "black" }}>
                  For cutting-edge developers
                </h5>
                <span style={{ color: "#d0d0d0" }}>
                  We’re opening up opportunities at the limits of Web3, allowing
                  novel emerging technologies to flourish.
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="media" style={{ padding: 10 }}>
              <img
                src="https://cdn.sanity.io/files/zg5gx8g4/production/abf5c4a941fa5ef6ff5d6156e260203190fe549c.svg"
                className="mr-3"
                alt="..."
              />
              <div className="media-body">
                <h5 className="mt-0" style={{ color: "black" }}>
                  For the Web3 community
                </h5>
                <span style={{ color: "#d0d0d0" }}>
                  Cartesi brings impossible ideas to life by fostering
                  collaboration, experimentation, openness and a willingness to
                  take risks and grow, together.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="container-fluid">
      <div
        className="row"
        style={{
          borderRadius: 10,
          border: "solid 1px  #d0d0d0",
          margin: 10,
          padding: 20
        }}
      >
        <div
          className="col-lg-12 col-md-12"
          style={{ borderRadius: 20, backgroundColor: "black", marginTop: 10 }}
        >
          <div className="row" style={{ color: "white" }}>
            <div className="col-md-5 col-lg-5 col-xs-12">
              <h2 style={{  padding: 20 }}>
                Start building with Cartesi Rollups
              </h2>
            </div>
            <div className="col-md-7 col-lg-7 col-xs-12" id="swap">
                <h2 style={{   padding: 20 }}>
                    Swap CTSI <br/>
                </h2>
                 <Buy />
                
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="container-fluid">
      <div className="row">
        <div
          className="col-lg-12 col-md-12"
          style={{ backgroundColor: "black", padding: 40 }}
        >
          <img
            src="/images/logocartesi.png"
            width={30}
            height={30}
            alt=""
            loading="lazy"
          />
          Cartesi
          <br />
          <p>Join our newsletter to stay up to date on features and releases</p>
          <p>© 2024 The Cartesi Foundation. All rights reserved.</p>
        </div>
      </div>
    </div>
  </>
  
 
  );
};
export default Home;
